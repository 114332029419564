import LogoImage from "../assets/images/docc.png";
import { Link } from "react-router-dom";

interface FooterProps {
  heading: string;
}

function Footer(props: FooterProps) {
  function footerLinkAction(): void {
    window.open(
      "https://www.linkedin.com/in/gunjan-sharma-a74332251/",
      "_blank"
    );
  }

  function openLink(): void {
    window.open("https://en.wikipedia.org/wiki/Web_colors", "_blank");
  }

  function heading(heading: string, url: string): JSX.Element {
    return (
      <Link to={url} target="_blank">
        <h1
          style={{ fontSize: "13px" }}
          className="text-white text-centre"
        >
          {heading}
        </h1>
      </Link>
    );
  }

  return (
    <footer className="w-full">
      <div className="h-340px w-full mt-24 flex flex-col items-center justify-between bg-black">
        <div className="w-full m-5 flex items-center justify-center">
          <img alt="" src={LogoImage} className="w-7 h-7" />
          <h1 className="text-center ml-4 text-white text-xl font-bold">
            {props.heading}
          </h1>
        </div>
        <Link to="https://docbay.in" target="_blank">
          <h2 className="text-white font-bold"> © Docbay.in™</h2>
        </Link>

        <div className="w-100%">
          <div className="p-5 container grid grid-cols-3 md:grid-cols-6 gap-5 w-full">
            {heading("JPG To PNG", "https://jpgtopng.docbay.in")}
            {heading("JPG To WEBP", "https://jpgtowebp.docbay.in")}
            {heading("JPG To GIF", "https://jpgtogif.docbay.in")}
            {heading("JPG To AVIF", "https://jpgtoavif.docbay.in")}
            {heading("JPG To TIFF", "https://jpgtotiff.docbay.in")}
            {heading("JPG To HEIC", "https://jpgtoheic.docbay.in")}

            {heading("PNG To JPEG", "https://pngtojpg.docbay.in")}
            {heading("PNG To WEBP", "https://pngtowebp.docbay.in")}
            {heading("PNG To GIF", "https://pngtogif.docbay.in")}
            {heading("PNG To AVIF", "https://pngtoavif.docbay.in")}
            {heading("PNG To TIFF", "https://pngtotiff.docbay.in")}
            {heading("PNG To HEIC", "https://pngtoheic.docbay.in")}

            {heading("WEBP To JPEG", "https://webptojpg.docbay.in")}
            {heading("WEBP To PNG", "https://webptopng.docbay.in")}
            {heading("WEBP To GIF", "https://webptogif.docbay.in")}
            {heading("WEBP To AVIF", "https://webptoavif.docbay.in")}
            {heading("WEBP To TIFF", "https://webptotiff.docbay.in")}
            {heading("WEBP To HEIC", "https://webptotoheic.docbay.in")}

            {heading("GIF To JPEG", "https://giftojpg.docbay.in")}
            {heading("GIF To PNG", "https://giftopng.docbay.in")}
            {heading("GIF To WEBP", "https://giftowebp.docbay.in")}
            {heading("GIF To AVIF", "https://giftoavif.docbay.in")}
            {heading("GIF To TIFF", "https://giftotiff.docbay.in")}
            {heading("GIF To HEIC", "https://giftoheic.docbay.in")}

            {heading("AVIF To JPEG", "https://aviftojpg.docbay.in")}
            {heading("AVIF To PNG", "https://aviftopng.docbay.in")}
            {heading("AVIF To WEBP", "https://aviftowebp.docbay.in")}
            {heading("AVIF To GIF", "https://aviftogif.docbay.in")}
            {heading("AVIF To TIFF", "https://aviftotiff.docbay.in")}
            {heading("AVIF To HEIC", "https://aviftoheic.docbay.in")}

            {heading("TIFF To JPEG", "https://tifftojpg.docbay.in")}
            {heading("TIFF To PNG", "https://tifftopng.docbay.in")}
            {heading("TIFF To WEBP", "https://tifftowebp.docbay.in")}
            {heading("TIFF To GIF", "https://tifftogif.docbay.in")}
            {heading("TIFF To AVIF", "https://tifftoavif.docbay.in")}
            {heading("TIFF To HEIC", "https://tifftoheic.docbay.in")}

            {heading("HEIC To JPEG", "https://heictojpg.docbay.in")}
            {heading("HEIC To PNG", "https://heictopng.docbay.in")}
            {heading("HEIC To WEBP", "https://heictowebp.docbay.in")}
            {heading("HEIC To GIF", "https://heictogif.docbay.in")}
            {heading("HEIC To TIFF", "https://heictotiff.docbay.in")}
            {heading("HEIC To AVIF", "https://heictoavif.docbay.in")}

            {heading("Compress JPEG", "https://compressjpg.docbay.in")}
            {heading("Compress PNG", "https://compresspng.docbay.in")}
            {heading("Compress WEBP", "https://compresswebp.docbay.in")}
            {heading("Compress GIF", "https://compressgif.docbay.in")}
            {heading("Compress TIFF", "https://compresstiff.docbay.in")}

            {/* {heading(
              "Youtube Video",
              "https://youtubevideo-downloader.netlify.app"
            )}
            {heading(
              "Youtube Audio",
              "https://youtubeaudio-downloader.netlify.app"
            )}
            {heading(
              "Youtube Shorts",
              "https://youtubeshorts-downloader.netlify.app"
            )}
            {heading(
              "Youtube Thumbnail",
              "https://youtubethumbnail-downloader.netlify.app"
            )}

            {heading(
              "Facebook Video",
              "https://facebookvideo-downloader.web.app"
            )}
            {heading("Facebook Shorts", "https://facebook-shorts.web.app")}
            {heading(
              "Facebook Thumbnail",
              "https://facebook-thumbnail.web.app"
            )}

            {heading(
              "Instagram Video",
              "https://instagramvideo-downloader.web.app"
            )}
            {heading(
              "Instagram Reels",
              "https://instagramreels-downloader.web.app"
            )}
            {heading(
              "Instagram Thumbnail",
              "https://instagram-thumbnail.web.app"
            )}

            {heading("Whois Lookup", "https://whois-lookups.web.app")}
            {heading("Geolocation Data", "https://geolocationq.web.app")}
            {heading("Seo Parser", "https://seo-scrapers.web.app")}
            {heading("Link Scrapper", "https://links-scrapper.web.app")}
            {heading("Images Scrapper", "https://images-scrapper.web.app")}
            {heading("Media Scrapper", "https://medias-scrapper.web.app")}

            {heading("Script Scrapper", "https://javascript-scrapper.web.app")}
            {heading("Semantic Parser", "https://semantic-scrapper.web.app")}
            {heading("Keyword Lookup", "https://keywords-lookup.web.app")} */}
          </div>
        </div>

        <br />
        <div className="border p-2 border-dashed border-gray-400">
          <Link to="https://www.geekforce.in" target="_blank">
            <h4 className="text-white text-xs">
              A Product From GeekForce Technologies
            </h4>
          </Link>
          <Link
            target="_blank"
            to="https://www.linkedin.com/in/gunjan-sharma-a74332251/"
          >
            <h4 className="text-white text-xs mt-1">
              Owned & Envisoned By Gunjan Sharma
            </h4>
          </Link>
        </div>
        <br />

        <div className="m-4">
          <h5 className="text-center text-white font-sans text-xs font-bold">
            © Docbay.in™ 2024-2030
          </h5>
          <Link to={"https://www.linkedin.com/in/gunjan-sharma-a74332251/"}>
            <h5 className="text-center m-1 text-white font-sans text-xs font-bold">
              Designed & Developed By Gunjan Sharma
            </h5>
          </Link>
          <h5 className="xs:mb-5 text-center text-white font-sans text-xs font-bold">
            Made With ❤ In India
          </h5>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
