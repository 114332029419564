import {
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  Pagination,
  Slider,
  TextField,
} from "@mui/material";
import SyntaxHighlighter from "react-syntax-highlighter";
import FilterIcon from "@mui/icons-material/Filter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import DownloadImage from "../assets/images/download.png";
import React, {
  ChangeEventHandler,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import ReactPlayer from "react-player";
import axios from "axios";
import { Image, Root } from "../extras/types";
import SingleComponent from "../components/SingleComponent";
import ImageComponent from "../components/ImageComponent";
import FeatureIntro from "../components/FeatureIntro";
import { ColorContext } from "../extras/ColorContext";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { IconButton } from "@mui/material";
import Dropzone, { useDropzone } from "react-dropzone";
import FeatureList from "../components/FeatureList";
import FeatureListPage from "../components/FeatureListPage";

let buffer_data: ArrayBuffer;
const API_BASE_URL = `https://appnor-backend.onrender.com/convert/v1/api/giftotiff`;

function GifToTiff(props: any) {
  const colorContex = useContext(ColorContext);
  const [videoUrl, setVideoUrl] = useState("");
  const [inVideoUrl, setInVideoUrl] = useState("");
  const [audioResponse, setAudioResponse] = useState<ArrayBuffer>();
  const [playVideo, setPlayVideo] = useState(false);
  const [isTermsAggred, setIsTermsAggred] = useState(false);
  const [isDownloadSuccess, setIsDownloadSuccess] = useState(false);
  const [open, setOpen] = React.useState(false);
  const scrollRef = useRef<any>(null);
  const [selectedFile, setSelectedFile] = useState<any>();
  const [shadowSelectedFile, setShadowSelectedFile] = useState<any>();
  const [fileSelected, setFileSelected] = useState<boolean>(false);
  const [compressScale, setCompressScale] = useState<number | number[]>(50);
  const [imageSrc, setImageSrc] = useState<any>(null);
  const [newSize, setNewSize] = useState(0);
  const [originalSize, setOriginalSize] = useState(0);

  useEffect(() => {
    scrollToDiv();
    return () => {};
  }, [colorContex.point]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  function handleChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): any {
    setVideoUrl(event.target.value);
    if (videoUrl !== "" || videoUrl.includes("youtu")) {
      //setPlayVideo(true);
    } else {
      setPlayVideo(false);
    }
  }

  function handleCheckboxChange(checked: boolean) {
    setIsTermsAggred(checked);
    //setPlayVideo(checked);
  }

  function closeBackdrop() {
    setTimeout(() => {
      handleClose();
    }, 3000);
  }

  function fetchDownloadableLink(): void {
    if (!isTermsAggred) {
      alert("Please Agree with our Terms & Condition before procedding..");
      return;
    }

    if (videoUrl === "" || !videoUrl.startsWith("https://www")) {
      alert("A Valid Website URL[https://www] is Required!!");
      return;
    }
    handleOpen();
    axios.post<Root>(API_BASE_URL + videoUrl).then(
      (result) => {
        console.log("Hitting Website Parser  API is successful");
        setPlayVideo(true);
        setInVideoUrl(videoUrl);

        //setAudioResponse(result.data);
        //setDisplayedItems(result.data.images.slice(0, itemsPerPage));
        setIsDownloadSuccess(true);
        setTimeout(() => {
          handleClose();
          setVideoUrl("");
        }, 5000);
      },
      (error) => {
        console.log("Something went wrong while hitting data.." + error);
        handleClose();
        alert("Something went wrong while hitting data.. [" + error + " ]");
      }
    );
  }

  async function triggerDownload(data: ArrayBuffer): Promise<any> {
    try {
      const blob = new Blob([data], { type: "image/tiff" });
      const downloadUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download =
        "converted(docbay.in)-" + shadowSelectedFile.name.replace("gif", "tiff");
      link.click();
    } catch (error) {
      console.log(`somwthing went wrong in buffer conversion : ${error}`);
    }
  }

  function requestServerImageOperation() {
    if (!isTermsAggred) {
      alert("Please Agree with our Terms & Condition before procedding..");
      return;
    }
    if (selectedFile === null || selectedFile === undefined) {
      alert("A Valid Image File is required for image compression..");
      return;
    }
    if (selectedFile.size / 1024 > 5000) {
      alert("File Size cannot be more than 5 MB!!");
      return;
    }
    if (selectedFile.type !== "image/gif") {
      alert("Only GIF files are allowed.");
      return;
    }
    handleOpen();
    const formData = new FormData();
    formData.append("jpgFile", selectedFile);
    formData.append("compressScale", compressScale.toString());

    axios
      .post(API_BASE_URL, formData, {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.status === 500) {
          alert("Something went wong while performing operations on document");
          return;
        }
        const blob = new Blob([response.data], { type: "image/tiff" });
        const downloadUrl = URL.createObjectURL(blob);
        setNewSize(blob.size / 1024);
        setImageSrc(downloadUrl);
        //triggerDownload(response.data);
        buffer_data = response.data;
        setAudioResponse(response.data);
        setIsDownloadSuccess(true);
        closeBackdrop();
        setSelectedFile(null);
        setFileSelected(false);
        console.log(response.data);
        console.log("dis : " + response.headers);
        console.log(`File compression successful : ${response.data}`);
      })
      .catch((error) => {
        handleClose();
        alert(`Error occured while compressing images on server : ${error}`);
        console.log(
          `Error occured while compressing images on server : ${error}`
        );
      });
  }

  function handleVideoPlay(): any {
    if (videoUrl === "" || !videoUrl.startsWith("https://")) {
      alert("A Valid Website URL is Required!!");
      return;
    }
    window.open(videoUrl, "_blank");
  }

  function scrollToDiv() {
    if (colorContex.point !== 0) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
      colorContex.setPoint(0);
    }
  }

  const handleSliderChange = (
    event: Event,
    newValue: number | number[]
  ): any => {
    setCompressScale(newValue);
  };

  const backdrop = (
    <React.Fragment>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <div className="flex flex-col items-center">
          <CircularProgress color="inherit" />
          <h1 className="font-extrabold m-2 text-white text-xl">
            Communicating with server...
          </h1>
        </div>
      </Backdrop>
    </React.Fragment>
  );

  function handleFileUpload<T extends File>(arg0: T): void {
    console.log(arg0.name + " " + arg0.size);
    setSelectedFile(arg0);
    setFileSelected(true);
    setShadowSelectedFile(null);
    setShadowSelectedFile(arg0);
    setOriginalSize(arg0.size / 1024);
  }

  function removeSelectedFile(event: any) {
    event.stopPropagation();
    if (!fileSelected) {
      return;
    }
    setSelectedFile(null);
    setShadowSelectedFile(null);
    setFileSelected(false);
  }

  return (
    <div className="md:m-10 sm:m-5 flex flex-col items-center justify-center">
      {backdrop}
      <FeatureIntro
        heading="Supercharged Document Compression & Conversion Tool⚡️"
        desc="Custom compression levels: You decide the perfect balance between size and quality.⚖️ Lightning-fast processing: Get those optimized images in a flash.⚡️ Mass conversion: Transform entire image collections with a single click. 🪄 Unlimited usage: No restrictions on file sizes or conversions.♾️ Secure and private: Your images are safe with us.✅"
        subheading="Docbay is here to lighten the load – and make them more flexible! Crush file sizes by up to 80% with our precision compression, mastering JPG, PNG, WEBP, GIF, AVIF, TIFF, and HEIC with ease."
      />
      <FeatureListPage />

      <div className="flex flex-col items-center border border-blue-500 shadow-lg p-4">
        <Dropzone
          onDrop={(acceptedFiles) => handleFileUpload(acceptedFiles[0])}
        >
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps()}
              ref={scrollRef}
              className="sm:w-1/2 flex flex-col items-center justify-center cursor-pointer border-dashed border-2 rounded-md border-black p-5"
            >
              <input {...getInputProps({ multiple: false, accept: ".jpg" })} />
              <FilterIcon fontSize="large" />
              <h3 className="mb-5 mt-5 text-center">
                Add your <strong>GIF images</strong> (max of 1) by dragging
                them here or click below to browse from your device.
              </h3>
              {fileSelected && (
                <img
                  className="shadow-md mb-2"
                  alt="upload files to compress"
                  width="100px"
                  height="100px"
                  src={URL.createObjectURL(selectedFile)}
                />
              )}
              {fileSelected && (
                <h2 className="font-bold line-clamp-1 font-mono">
                  {selectedFile.name}
                </h2>
              )}
              {fileSelected && (
                <p
                  onClick={removeSelectedFile}
                  className="text-blue-700 underline"
                >
                  Remove file
                </p>
              )}
              {!fileSelected && (
                <p className="text-blue-700 underline">Upload File</p>
              )}
            </div>
          )}
        </Dropzone>

        <br />
        <div className="sm:w-1/2 text-center m-5">
          <h1>
            Image Compress Level <strong>{compressScale}</strong>
          </h1>
          <div className="flex justify-between items-center">
            <RemoveIcon />
            <Slider
              className="ml-2 mr-2"
              min={1}
              max={100}
              value={Number(compressScale)}
              onChange={handleSliderChange}
            />
            <AddIcon />
          </div>
          <h4 className="text-xs mt-2">
            <strong>
              Only Docbay.in™ lets you choose quality of resulting image file.
            </strong>
            Image compression level lets you control precise image compression
            quality. Choose from 1-100, by default is set 50
          </h4>
        </div>

        {/* <div className="flex flex-col items-center border shadow-lg p-4"> */}
        <Button
          onClick={requestServerImageOperation}
          sx={{ marginTop: "20px", marginBottom: "10px", width: "200px" }}
          variant="contained"
        >
          Convert Image
        </Button>
        <br />

        <h3 className="text-xs text-center w-80 m-2 text-gray-600">
          A direct list of result will get triggered if image has only one
          format else a list of downloadable image will get presented.
        </h3>
        <div className="flex items-center justify-center">
          <Checkbox
            onChange={(e) => handleCheckboxChange(e.target.checked)}
            defaultChecked={false}
          />
          <h3 className="text-xs">
            By compressing files you agree to our terms & conditions for fair
            usages policy and file storgae.
          </h3>
        </div>
        <Divider color="black" />
      </div>

      <br />
      <br />
      {isDownloadSuccess && (
        <div className="border-2 text-center border-blue-500 shadow-sm p-4 mb-8 ml-2 mr-2">
          <div className="flex flex-col items-center md:flex-row font-mono mb-5 justify-center">
            <h3 className="font-bold text-xl">File Converted Successfully</h3>
            <img
              className="m-2"
              width="30px"
              height="30px"
              alt="download"
              src={DownloadImage}
            />
            <img
              className="animate-ping"
              width="30px"
              height="30px"
              alt="download"
              src={DownloadImage}
            />
          </div>
        </div>
      )}

      {isDownloadSuccess && (
        <img
          alt="screenshot-file"
          className="mt-5 mb-8 lg:w-1/2 md:w-1/2"
          src={imageSrc}
        />
      )}

      <div className="mt-6 mb-6">
        <h1 className="font-mono">
          Original File Size: <strong>{Math.round(originalSize)} KB</strong>
        </h1>
        <h1 className="font-mono">
          Size After Compression: <strong>{Math.round(newSize)} KB</strong>
        </h1>
      </div>

      {isDownloadSuccess && (
        <Button
          sx={{ fontWeight: "bold", color: "blue" }}
          variant="outlined"
          onClick={async () => await triggerDownload(audioResponse!)}
        >
          Download Converted Image File
        </Button>
      )}
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}

export default GifToTiff;
