import FeatureList from "./FeatureList";
import LogoImage from "../assets/images/docc.png";
import PrivacyImage from "../assets/images/privacy.png";
import UpdateImage from "../assets/images/updated.png";
import FreeImage from "../assets/images/free.png";

function FeatureListPage(props: any) {
  return (
    <div className="container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 w-full">
      <FeatureList
        image={FreeImage}
        heading="Always Free"
        desc="Free yourself from file format frustrations! Docbay empowers you to convert and compress documents without any paywalls, restrictions, or hidden fees. Get the flexibility you deserve, without the cost."
      />

      <FeatureList
        image={UpdateImage}
        heading="Constant Updates"
        desc="Docbay is always evolving, just like your document needs. Enjoy regular updates that bring fresh features, improved performance, and enhanced compatibility, ensuring you're always working with the best tools available."
      />

      <FeatureList
        image={LogoImage}
        heading="From Docbay.in"
        desc="Trust the experts in document transformation! Docbay.in has crafted a suite of powerful tools designed to make your digital life smoother and more efficient. Experience the difference that comes from a team dedicated to document excellence."
      />

      <FeatureList
        image={PrivacyImage}
        heading="Unmatched Privacy"
        desc="We never store your uploaded images on our server. We perform all
        operations in-memory and return the buffer of resulting image to client
        from memory itself and destroy held buffer memory afterwards. So your
        iamges are safe."
      />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}

export default FeatureListPage;
