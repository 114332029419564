import FaqComponent from "./FaqComponent";

function FaqList(props: any) {
  return (
    <div className="p-4 mt-20 lg:mx-20">
      <h1>FAQ (How it works)</h1>
      <div
        className="mt-3 mb-3"
        style={{ borderBottomWidth: "1px", borderBottomColor: "lightgray" }}
      />
      <FaqComponent
        faq="How does Docbay ensure image quality during compression?"
        answer="We use advanced algorithms that prioritize both file size reduction and visual fidelity. Our goal is to achieve the smallest possible size without compromising the clarity and detail of your images. You can also experiment with different compression levels to find the perfect balance for your needs."
      />
      <FaqComponent
        faq="What are the benefits of using Docbay for image conversion?"
        answer="Wide format support: We handle JPG, PNG, WEBP, GIF, AVIF, TIFF, and HEIC, providing flexibility for different use cases. Batch conversion: Easily transform multiple images in a single operation, saving you time and effort. Preserving metadata: Docbay preserves essential image information like EXIF data during conversion, ensuring compatibility with various platforms and software."
      />
      <FaqComponent
        faq="How does Docbay protect my privacy and security?"
        answer="No server storage: Your images are never stored on our servers. We process them entirely in memory and immediately delete them after returning the compressed or converted files. Encrypted connections: All data transfers are secured using HTTPS for added protection."
      />

      <FaqComponent
        faq="Does Docbay have any limitations on image size or conversion quantities?"
        answer="No! We offer unlimited usage with 7 MB size restrictions on file sizes or the number of images you can compress or convert."
      />
      <FaqComponent
        faq="What are the best practices for choosing compression levels and formats?"
        answer="Balance quality and size: Experiment with different compression levels to find the optimal balance for your needs. Visually inspect the results to ensure acceptable quality. Consider format compatibility: Choose formats that are widely supported by browsers and devices for web images. For high-quality printing, consider TIFF or PNG."
      />
      <FaqComponent
        faq="Does Docbay integrate with other tools or platforms?"
        answer="While we don't currently offer direct integrations, you can easily incorporate Docbay's compression and conversion capabilities into your workflows using our API or web interface. We're always exploring new integration opportunities to enhance your experience."
      />

      <FaqComponent
        faq="Does Docbay offer any lossless compression options?"
        answer="Yes, depending on the image format, Docbay provides lossless compression options that maintain the original size and quality while eliminating redundant data. This is ideal for preserving archival integrity or when even minor quality changes are unacceptable."
      />
      <FaqComponent
        faq="Can I customize the output filename during conversion?"
        answer="Currently, Docbay automatically generates new filenames for converted images to avoid overwriting existing files. However, we plan to implement an option for custom naming in future updates."
      />
      <FaqComponent
        faq="What happens if my internet connection drops during the process?"
        answer="Docbay automatically resends failed uploads or conversions within a set timeframe. If the failure persists, you'll receive an error notification."
      />

      <FaqComponent
        faq="Are there any limitations on the type of images I can compress or convert?"
        answer="While Docbay supports a wide range of formats, limitations might exist for specific content types. Images containing copyrighted material, for example, might not be processed. Check our terms of service for details."
      />
    </div>
  );
}

export default FaqList;
