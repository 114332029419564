import { useEffect, useRef, useState } from "react";
import "./App.css";
import Footer from "./components/Footer";
import { ColorContext } from "./extras/ColorContext";
import UpMenu from "./components/UpMenu";
import Home from "./pages/HomePage";
import SimpleIntro from "./components/SimpleIntro";
import FaqList from "./components/FaqList";
import NewsLater from "./components/NewsLater";
import { useLocation } from "react-router-dom";
import CompressPng from "./pages/CompressPng";
import { JsxElement } from "typescript";
import Compresswebp from "./pages/CompressWeb";
import CompressGif from "./pages/CompressGif";
import CompressTiff from "./pages/CompressTiff";
import JpgToPng from "./pages/JpgToPng";
import JpgToWebp from "./pages/JpgToWebp";
import JpgToGif from "./pages/JpgToGif";
import JpgToAvif from "./pages/JpgToAvif";
import JpgToTiff from "./pages/JpgToTiff";
import JpgToHeic from "./pages/JpgToHeic";
import PngToJpg from "./pages/PngToJpg";
import PngToWebp from "./pages/PngToWebp";
import PngToAvif from "./pages/PngToAvif";
import PngToTiff from "./pages/PngToTiff";
import PngToHeic from "./pages/PngToHeic";
import WebpToJpg from "./pages/WebpToJpg";
import WebpToPng from "./pages/WebpToPng";
import WebpToGif from "./pages/WebpToGif";
import WebpToTiff from "./pages/WebpToTiff";
import WebpToHeic from "./pages/WebpToHeic";
import WebpToAvif from "./pages/WebpToAvif";
import GifToJpg from "./pages/GifToJpg";
import GifToPng from "./pages/GifToPng";
import GifToWebp from "./pages/GifToWebp";
import GifToAvif from "./pages/GifToAvif";
import GifToTiff from "./pages/GifToTiff";
import GifToHeic from "./pages/GifToHeic";
import AvifToTiff from "./pages/AvifToTiff";
import AvifToJpg from "./pages/AvifToJpg";
import AvifToPng from "./pages/AvifToPng";
import AvifToWebp from "./pages/AvifToWebp";
import AvifToGif from "./pages/AvifToGif";
import AvifToHeic from "./pages/AvifToHeic";
import TiffToJpg from "./pages/TiffToJpg";
import TiffToPng from "./pages/TiffToPng";
import TiffToWebp from "./pages/TiffToWebp";
import TiffToGif from "./pages/TiffToGif";
import TiffToAvif from "./pages/TiffToAvif";
import TiffToHeic from "./pages/TiffToHeic";
import HeicToJpg from "./pages/HeicToJpg";
import HeicToPng from "./pages/HeicToPng";
import HeicToWebp from "./pages/HeicToWebp";
import HeicToGif from "./pages/HeicToGif";
import HeicToAvif from "./pages/HeicToAvif";
import HeicToTiff from "./pages/HeicToTiff";
import PngToGif from "./pages/PngToGif";
const defTitle =
  "Docbay.in™ | Ultimate Document Compression & Conversation Tool | Docbay™ For Documents [Docbay™.in]";
function App() {
  const [subdomain, setSubdomain] = useState<string>("");
  const [color, setColor] = useState<string>("");
  const [point, setPoint] = useState<number>(0);

  useEffect(() => {
    getSubDomain();
    return () => {};
  }, []);

  const handleColorChange = (color: string) => {
    setColor(color);
  };
  const handlePointChange = (p: number) => {
    setPoint(p);
  };

  function getSubDomain(): string {
    const fullHostname = window.location.hostname;
    console.log(`fullHostname : ${fullHostname}`);
    const parts = fullHostname.split(".");
    console.log("fh : " + fullHostname);

    if (parts.length >= 2) {
      const subdomain = parts[0];
      console.log(`subdomain : ${subdomain}`);
      console.log("sub domain yes");
      setSubdomain(subdomain);
      return subdomain;
    } else {
      console.log("subdomain no");
      setSubdomain("");
      return "";
    }
  }

  function serveRequestedMetadata(alias: string): string[] {
    switch (alias) {
      case "compressjpg":
        document.title = `Ultimate JPEG Compressor | ${defTitle}`;
        return [
          "Ultimate JPEG Compressor",
          "Compress JPEGs With Force of Elephant🐘🐘",
          "Compress JPEG",
          "JPEG Compressor",
        ];

      case "compresspng":
        document.title = `Ultimate PNG Compressor | ${defTitle}`;
        return [
          "Ultimate PNG Compressor",
          "Compress PNGs With Force of Elephant🐘🐘",
          "Compress PNG",
          "PNG Compressor",
        ];

      case "compresswebp":
        document.title = `Ultimate WEBP Compressor | ${defTitle}`;
        return [
          "Ultimate WEBP Compressor",
          "Compress WEBP With Force of Elephant🐘🐘",
          "Compress WEBP",
          "WEBP Compressor",
        ];

      case "compressgif":
        document.title = `Ultimate GIF Compressor | ${defTitle}`;
        return [
          "Ultimate GIF Compressor",
          "Compress GIFs With Force of Elephant🐘🐘",
          "Compress GIF",
          "GIF Compressor",
        ];

      case "compresstiff":
        document.title = `Ultimate TIFF Compressor | ${defTitle}`;
        return [
          "Ultimate TIFF Compressor",
          "Compress TIFFs With Force of Elephant🐘🐘",
          "Compress TIFF",
          "TIFF Compressor",
        ];

      //Jpgto[] conversations
      case "jpgtopng":
        document.title = `JPEG To PNG - Convert JPEG TO PNG | ${defTitle}`;
        return [
          "Convert JPEG To PNG",
          "Convert JPEG Files To PNG Files Seamlessly💫✨",
          "Convert Now",
          "JPEG To PNG",
        ];

      case "jpgtowebp":
        document.title = `JPEG To WEBP - Convert JPEG TO WEBP | ${defTitle}`;
        return [
          "Convert JPEG To WEBP",
          "Convert JPEG Files To WEBP Seamlessly💫✨",
          "Convert Now",
          "JPEG To WEBP",
        ];

      case "jpgtogif":
        document.title = `JPEG To GIF - Convert JPEG TO GIF | ${defTitle}`;
        return [
          "Convert JPEG To GIF",
          "Convert JPEG Files To GIF Seamlessly💫✨",
          "Convert Now",
          "JPEG To GIF",
        ];

      case "jpgtoavif":
        document.title = `JPEG To AVIF - Convert JPEG TO AVIF | ${defTitle}`;
        return [
          "Convert JPEG To AVIF",
          "Convert JPEG Files To AVIF Seamlessly💫✨",
          "Convert Now",
          "JPEG To AVIF",
        ];

      case "jpgtotiff":
        document.title = `JPEG To TIFF - Convert JPEG TO TIFF | ${defTitle}`;
        return [
          "Convert JPEG To TIFF",
          "Convert JPEG Files To TIFF Seamlessly💫✨",
          "Convert Now",
          "JPEG To TIFF",
        ];

      case "jpgtoheic":
        document.title = `JPEG To HEIC - Convert JPEG TO HEIC | ${defTitle}`;
        return [
          "Convert JPEG To HEIC",
          "Convert JPEG Files To HEIC Seamlessly💫✨",
          "Convert Now",
          "JPEG To HEIC",
        ];

      //Png to <> conversations
      case "pngtojpg":
        document.title = `PNG To JPEG - Convert PNG TO JPEG | ${defTitle}`;
        return [
          "Convert PNG To JPEG",
          "Convert PNG Files To JPEG Seamlessly💫✨",
          "Convert Now",
          "PNG To JPEG",
        ];

      case "pngtowebp":
        document.title = `PNG To WEBP - Convert PNG TO WEBP | ${defTitle}`;
        return [
          "Convert PNG To WEBP",
          "Convert PNG Files To WEBP Seamlessly💫✨",
          "Convert Now",
          "PNG To WEBP",
        ];

      case "pngtogif":
        document.title = `PNG To GIF - Convert PNG TO GIF | ${defTitle}`;
        return [
          "Convert PNG To GIF",
          "Convert PNG Files To GIF Seamlessly💫✨",
          "Convert Now",
          "PNG To GIF",
        ];

      case "pngtoavif":
        document.title = `PNG To AVIF - Convert PNG TO AVIF | ${defTitle}`;
        return [
          "Convert PNG To AVIF",
          "Convert PNG Files To AVIF Seamlessly💫✨",
          "Convert Now",
          "PNG To AVIF",
        ];

      case "pngtotiff":
        document.title = `PNG To TIFF - Convert PNG TO TIFF | ${defTitle}`;
        return [
          "Convert PNG To TIFF",
          "Convert PNG Files To TIFF Seamlessly💫✨",
          "Convert Now",
          "PNG To TIFF",
        ];

      case "pngtoheic":
        document.title = `PNG To HEIC - Convert PNG TO HEIC | ${defTitle}`;
        return [
          "Convert PNG To HEIC",
          "Convert PNG Files To HEIC Seamlessly💫✨",
          "Convert Now",
          "PNG To HEIC",
        ];

      //Webp to <> conversations
      case "webptojpg":
        document.title = `WEBP To JPEG - Convert WEBP TO JPEG | ${defTitle}`;
        return [
          "Convert WEBP To JPEG",
          "Convert WEBP Files To JPEG Seamlessly💫✨",
          "Convert Now",
          "WEBP To JPEG",
        ];

      case "webptopng":
        document.title = `WEBP To PNG - Convert WEBP TO PNG | ${defTitle}`;
        return [
          "Convert WEBP To PNG",
          "Convert WEBP Files To PNG Seamlessly💫✨",
          "Convert Now",
          "WEBP To PNG",
        ];

      case "webptogif":
        document.title = `WEBP To GIF - Convert WEBP TO GIF | ${defTitle}`;
        return [
          "Convert WEBP To GIF",
          "Convert WEBP Files To GIF Seamlessly💫✨",
          "Convert Now",
          "WEBP To GIF",
        ];

      case "webptoavif":
        document.title = `WEBP To AVIF - Convert WEBP TO AVIF | ${defTitle}`;
        return [
          "Convert WEBP To AVIF",
          "Convert WEBP Files To AVIF Seamlessly💫✨",
          "Convert Now",
          "WEBP To AVIF",
        ];

      case "webptotiff":
        document.title = `WEBP To TIFF - Convert WEBP TO TIFF | ${defTitle}`;
        return [
          "Convert WEBP To TIFF",
          "Convert WEBP Files To TIFF Seamlessly💫✨",
          "Convert Now",
          "WEBP To TIFF",
        ];

      case "webptoheic":
        document.title = `WEBP To HEIC - Convert WEBP TO HEIC | ${defTitle}`;
        return [
          "Convert WEBP To HEIC",
          "Convert WEBP Files To HEIC Seamlessly💫✨",
          "Convert Now",
          "WEBP To WEBP",
        ];

      //Gif to <> conversations
      case "giftojpg":
        document.title = `GIF To JPEG - Convert GIF TO JPEG | ${defTitle}`;
        return [
          "Convert GIF To JPEG",
          "Convert GIF Files To JPEG Seamlessly💫✨",
          "Convert Now",
          "GIF To JPEG",
        ];

      case "giftopng":
        document.title = `GIF To PNG - Convert GIF TO PNG | ${defTitle}`;
        return [
          "Convert GIF To PNG",
          "Convert GIF Files To PNG Seamlessly💫✨",
          "Convert Now",
          "GIF To PNG",
        ];

      case "giftowebp":
        document.title = `GIF To WEBP - Convert GIF TO WEBP | ${defTitle}`;
        return [
          "Convert GIF To WEBP",
          "Convert GIF Files To GIF Seamlessly💫✨",
          "Convert Now",
          "GIF To WEBP",
        ];

      case "giftoavif":
        document.title = `GIF To AVIF - Convert GIF TO AVIF | ${defTitle}`;
        return [
          "Convert GIF To AVIF",
          "Convert GIF Files To AVIF Seamlessly💫✨",
          "Convert Now",
          "GIF To AVIF",
        ];

      case "giftotiff":
        document.title = `GIF To TIFF - Convert GIF TO TIFF | ${defTitle}`;
        return [
          "Convert GIF To TIFF",
          "Convert GIF Files To TIFF Seamlessly💫✨",
          "Convert Now",
          "GIF To TIFF",
        ];

      case "heic":
        document.title = `GIF To HEIC - Convert GIF TO GIF | ${defTitle}`;
        return [
          "Convert GIF To HEIC",
          "Convert GIF Files To HEIC Seamlessly💫✨",
          "Convert Now",
          "GIF To HEIC",
        ];

      //Avif to <> conversations

      case "aviftojpg":
        document.title = `AVIF To JPEG - Convert AVIF TO JPEG | ${defTitle}`;
        return [
          "Convert AVIF To JPEG",
          "Convert AVIF Files To JPEG Seamlessly💫✨",
          "Convert Now",
          "AVIF To JPEG",
        ];

      case "aviftopng":
        document.title = `AVIF To PNG - Convert AVIF TO PNG | ${defTitle}`;
        return [
          "Convert AVIF To PNG",
          "Convert AVIF Files To PNG Seamlessly💫✨",
          "Convert Now",
          "AVIF To PNG",
        ];

      case "aviftowebp":
        document.title = `AVIF To WEBP - Convert AVIF TO WEBP | ${defTitle}`;
        return [
          "Convert AVIF To WEBP",
          "Convert AVIF Files To WEBP Seamlessly💫✨",
          "Convert Now",
          "AVIF To WEBP",
        ];

      case "aviftogif":
        document.title = `AVIF To GIF - Convert AVIF TO GIF | ${defTitle}`;
        return [
          "Convert AVIF To GIF",
          "Convert AVIF Files To GIF Seamlessly💫✨",
          "Convert Now",
          "AVIF To GIF",
        ];

      case "aviftotiff":
        document.title = `AVIF To TIFF - Convert AVIF TO TIFF | ${defTitle}`;
        return [
          "Convert AVIF To TIFF",
          "Convert AVIF Files To TIFF Seamlessly💫✨",
          "Convert Now",
          "AVIF To TIFF",
        ];

      case "aviftoheic":
        document.title = `AVIF To HEIC - Convert AVIF TO HEIC | ${defTitle}`;
        return [
          "Convert AVIF To HEIC",
          "Convert AVIF Files To HEIC Seamlessly💫✨",
          "Convert Now",
          "AVIF To HEIC",
        ];

      //Tiff to <> conversations

      case "tifftojpg":
        document.title = `TIFF To JPEG - Convert TIFF TO JPEG | ${defTitle}`;
        return [
          "Convert TIFF To JPEG",
          "Convert TIFF Files To JPEG Seamlessly💫✨",
          "Convert Now",
          "TIFF To JPEG",
        ];

      case "tifftopng":
        document.title = `TIFF To PNG - Convert TIFF TO PNG | ${defTitle}`;
        return [
          "Convert TIFF To PNG",
          "Convert TIFF Files To PNG Seamlessly💫✨",
          "Convert Now",
          "TIFF To PNG",
        ];

      case "tifftowebp":
        document.title = `TIFF To WEBP - Convert TIFF TO WEBP | ${defTitle}`;
        return [
          "Convert TIFF To WEBP",
          "Convert TIFF Files To WEBP Seamlessly💫✨",
          "Convert Now",
          "TIFF To WEBP",
        ];

      case "tifftogif":
        document.title = `TIFF To GIF - Convert TIFF TO GIF | ${defTitle}`;
        return [
          "Convert TIFF To GIF",
          "Convert TIFF Files To GIF Seamlessly💫✨",
          "Convert Now",
          "TIFF To GIF",
        ];

      case "tifftoavif":
        document.title = `TIFF To AVIF - Convert TIFF TO AVIF | ${defTitle}`;
        return [
          "Convert TIFF To AVIF",
          "Convert TIFF Files To AVIF Seamlessly💫✨",
          "Convert Now",
          "TIFF To AVIF",
        ];

      case "tifftoheic":
        document.title = `TIFF To HEIC - Convert TIFF TO HEIC | ${defTitle}`;
        return [
          "Convert TIFF To HEIC",
          "Convert TIFF Files To HEIC Seamlessly💫✨",
          "Convert Now",
          "TIFF To HEIC",
        ];

      //Heic to <> conversations
      case "heictojpg":
        document.title = `HEIC To JPEG - Convert HEIC TO JPEG | ${defTitle}`;
        return [
          "Convert HEIC To JPEG",
          "Convert HEIC Files To JPEG Seamlessly💫✨",
          "Convert Now",
          "HEIC To JPEG",
        ];

      case "heictopng":
        document.title = `HEIC To PNG - Convert HEIC TO PNG | ${defTitle}`;
        return [
          "Convert HEIC To PNG",
          "Convert HEIC Files To PNG Seamlessly💫✨",
          "Convert Now",
          "HEIC To PNG",
        ];

      case "heictowebp":
        document.title = `HEIC To WEBP - Convert HEIC TO WEBP | ${defTitle}`;
        return [
          "Convert HEIC To WEBP",
          "Convert HEIC Files To WEBP Seamlessly💫✨",
          "Convert Now",
          "HEIC To WEBP",
        ];

      case "heictogif":
        document.title = `HEIC To GIF - Convert HEIC TO GIF | ${defTitle}`;
        return [
          "Convert HEIC To GIF",
          "Convert HEIC Files To GIF Seamlessly💫✨",
          "Convert Now",
          "HEIC To GIF",
        ];

      case "heictoavif":
        document.title = `HEIC To AVIF - Convert HEIC TO AVIF | ${defTitle}`;
        return [
          "Convert HEIC To AVIF",
          "Convert HEIC Files To AVIF Seamlessly💫✨",
          "Convert Now",
          "HEIC To AVIF",
        ];

      case "heictotiff":
        document.title = `HEIC To TIFF - Convert HEIC TO TIFF | ${defTitle}`;
        return [
          "Convert HEIC To TIFF",
          "Convert HEIC Files To TIFF Seamlessly💫✨",
          "Convert Now",
          "HEIC To TIFF",
        ];
      default:
        document.title = `${defTitle}`;
        return [
          "Ultimate JPEG Compressor",
          "Compress JPEGs With Force of Elephant🐘🐘",
          "Compress JPEG",
          "JPEG Compressor",
        ];
    }
  }

  function serveRequestedPage(): JSX.Element {
    switch (subdomain) {
      case "compressjpg":
        return <Home />;

      case "compresspng":
        return <CompressPng />;

      case "compresswebp":
        return <Compresswebp />;

      case "compressgif":
        return <CompressGif />;

      case "compresstiff":
        return <CompressTiff />;

      //<JpgtoPng></JpgtoPng>
      case "jpgtopng":
        return <JpgToPng />;

      case "jpgtowebp":
        return <JpgToWebp />;

      case "jpgtogif":
        return <JpgToGif />;

      case "jpgtoavif":
        return <JpgToAvif />;

      case "jpgtotiff":
        return <JpgToTiff />;

      case "jpgtoheic":
        return <JpgToHeic />;

      //Png to <> conversations
      case "pngtojpg":
        return <PngToJpg />;

      case "pngtowebp":
        return <PngToWebp />;

      case "pngtogif":
        return <PngToGif />;

      case "pngtoavif":
        return <PngToAvif />;

      case "pngtotiff":
        return <PngToTiff />;

      case "pngtoheic":
        return <PngToHeic />;

      //Webp to <> conversations
      case "webptojpg":
        return <WebpToJpg />;

      case "webptopng":
        return <WebpToPng />;

      case "webptogif":
        return <WebpToGif />;

      case "webptotiff":
        return <WebpToTiff />;

      case "webptoheic":
        return <WebpToHeic />;

      case "webptoavif":
        return <WebpToAvif />;

      //Gif to <> conversations
      case "giftojpg":
        return <GifToJpg />;

      case "giftopng":
        return <GifToPng />;

      case "giftowebp":
        return <GifToWebp />;

      case "giftoavif":
        return <GifToAvif />;

      case "giftotiff":
        return <GifToTiff />;

      case "giftoheic":
        return <GifToHeic />;

      //Avif to <> conversations
      case "aviftotiff":
        return <AvifToTiff />;

      case "aviftojpg":
        return <AvifToJpg />;

      case "aviftopng":
        return <AvifToPng />;

      case "aviftowebp":
        return <AvifToWebp />;

      case "aviftogif":
        return <AvifToGif />;

      case "aviftoheic":
        return <AvifToHeic />;

      //Tiff to <> conversations
      case "tifftojpg":
        return <TiffToJpg />;

      case "tifftopng":
        return <TiffToPng />;

      case "tifftowebp":
        return <TiffToWebp />;

      case "tifftogif":
        return <TiffToGif />;

      case "tifftoavif":
        return <TiffToAvif />;

      case "tifftoheic":
        return <TiffToHeic />;

      //Heic to <> conversations
      case "heictojpg":
        return <HeicToJpg />;

      case "heictopng":
        return <HeicToPng />;

      case "heictowebp":
        return <HeicToWebp />;

      case "heictogif":
        return <HeicToGif />;

      case "heictoavif":
        return <HeicToAvif />;

      case "heictotiff":
        return <HeicToTiff />;

      default:
        return <Home />;
    }
  }

  return (
    <div className="overflow-hidden">
      <UpMenu heading={serveRequestedMetadata(subdomain)[3]} />
      <ColorContext.Provider
        value={{
          color: color,
          point: point,
          setPoint: handlePointChange,
          setColor: handleColorChange,
        }}
      >
        <SimpleIntro
          tag="FREE TOOL"
          heading={serveRequestedMetadata(subdomain)[0]}
          subtitle={serveRequestedMetadata(subdomain)[1]}
          btntext={serveRequestedMetadata(subdomain)[2]}
        />
        {serveRequestedPage()}
      </ColorContext.Provider>
      <FaqList />
      <NewsLater />
      <Footer heading={serveRequestedMetadata(subdomain)[3]} />
    </div>
  );
}

export default App;
